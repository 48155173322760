.svg-QndVMHUD3{
  /*
  Used In:
  uebung_9_1_vor.svg, elements.2.elements.0.attributes.fill
  uebung_9_2_vor.svg, elements.2.elements.0.attributes.fill
  uebung_9_3_vor.svg, elements.2.elements.0.attributes.fill
  uebung_9_4_vor.svg, elements.2.elements.1.attributes.fill
  uebung_9_5_vor.svg, elements.2.elements.0.attributes.fill
  */
  fill: #FFFFFF;
}

.svg-nhZYvBrxUc{
  /*
  Used In:
  uebung_9_1_vor.svg, elements.2.elements.1.attributes.fill
  uebung_9_1_vor.svg, elements.2.elements.2.attributes.fill
  uebung_9_2_vor.svg, elements.2.elements.1.attributes.fill
  uebung_9_2_vor.svg, elements.2.elements.2.attributes.fill
  uebung_9_3_vor.svg, elements.2.elements.1.attributes.fill
  uebung_9_3_vor.svg, elements.2.elements.2.attributes.fill
  uebung_9_3_vor.svg, elements.2.elements.3.attributes.fill
  uebung_9_3_vor.svg, elements.2.elements.4.attributes.fill
  uebung_9_3_vor.svg, elements.2.elements.5.attributes.fill
  uebung_9_4_vor.svg, elements.2.elements.2.attributes.fill
  uebung_9_5_vor.svg, elements.2.elements.1.attributes.fill
  uebung_9_5_vor.svg, elements.2.elements.2.attributes.fill
  uebung_9_5_vor.svg, elements.2.elements.3.attributes.fill
  uebung_9_5_vor.svg, elements.2.elements.4.attributes.fill
  */
  fill: none;
}

.svg-flnGGCej4I{
  /*
  Used In:
  uebung_9_1_vor.svg, elements.2.elements.0.attributes.stroke
  uebung_9_1_vor.svg, elements.2.elements.1.attributes.stroke
  uebung_9_1_vor.svg, elements.2.elements.2.attributes.stroke
  uebung_9_2_vor.svg, elements.2.elements.0.attributes.stroke
  uebung_9_2_vor.svg, elements.2.elements.1.attributes.stroke
  uebung_9_2_vor.svg, elements.2.elements.2.attributes.stroke
  uebung_9_3_vor.svg, elements.2.elements.0.attributes.stroke
  uebung_9_3_vor.svg, elements.2.elements.1.attributes.stroke
  uebung_9_3_vor.svg, elements.2.elements.2.attributes.stroke
  uebung_9_3_vor.svg, elements.2.elements.3.attributes.stroke
  uebung_9_3_vor.svg, elements.2.elements.4.attributes.stroke
  uebung_9_3_vor.svg, elements.2.elements.5.attributes.stroke
  uebung_9_4_vor.svg, elements.2.elements.1.attributes.stroke
  uebung_9_4_vor.svg, elements.2.elements.2.attributes.stroke
  uebung_9_5_vor.svg, elements.2.elements.0.attributes.stroke
  uebung_9_5_vor.svg, elements.2.elements.1.attributes.stroke
  uebung_9_5_vor.svg, elements.2.elements.2.attributes.stroke
  uebung_9_5_vor.svg, elements.2.elements.3.attributes.stroke
  uebung_9_5_vor.svg, elements.2.elements.4.attributes.stroke
  */
  stroke: #575756;
}

.svg-zyh9qxhXjL{
  /*
  Used In:
  uebung_9_1_vor.svg, elements.2.elements.0.attributes.stroke-width
  uebung_9_1_vor.svg, elements.2.elements.1.attributes.stroke-width
  uebung_9_1_vor.svg, elements.2.elements.2.attributes.stroke-width
  uebung_9_2_vor.svg, elements.2.elements.0.attributes.stroke-width
  uebung_9_2_vor.svg, elements.2.elements.1.attributes.stroke-width
  uebung_9_2_vor.svg, elements.2.elements.2.attributes.stroke-width
  uebung_9_3_vor.svg, elements.2.elements.0.attributes.stroke-width
  uebung_9_3_vor.svg, elements.2.elements.1.attributes.stroke-width
  uebung_9_3_vor.svg, elements.2.elements.2.attributes.stroke-width
  uebung_9_3_vor.svg, elements.2.elements.3.attributes.stroke-width
  uebung_9_3_vor.svg, elements.2.elements.4.attributes.stroke-width
  uebung_9_3_vor.svg, elements.2.elements.5.attributes.stroke-width
  uebung_9_4_vor.svg, elements.2.elements.1.attributes.stroke-width
  uebung_9_4_vor.svg, elements.2.elements.2.attributes.stroke-width
  uebung_9_5_vor.svg, elements.2.elements.0.attributes.stroke-width
  uebung_9_5_vor.svg, elements.2.elements.1.attributes.stroke-width
  uebung_9_5_vor.svg, elements.2.elements.2.attributes.stroke-width
  uebung_9_5_vor.svg, elements.2.elements.3.attributes.stroke-width
  uebung_9_5_vor.svg, elements.2.elements.4.attributes.stroke-width
  */
  stroke-width: 4;
}

.svg-41wqh2RS75{
  /*
  Used In:
  uebung_9_1_vor.svg, elements.2.elements.0.attributes.stroke-linecap
  uebung_9_1_vor.svg, elements.2.elements.1.attributes.stroke-linecap
  uebung_9_1_vor.svg, elements.2.elements.2.attributes.stroke-linecap
  uebung_9_2_vor.svg, elements.2.elements.0.attributes.stroke-linecap
  uebung_9_2_vor.svg, elements.2.elements.1.attributes.stroke-linecap
  uebung_9_2_vor.svg, elements.2.elements.2.attributes.stroke-linecap
  uebung_9_3_vor.svg, elements.2.elements.0.attributes.stroke-linecap
  uebung_9_3_vor.svg, elements.2.elements.1.attributes.stroke-linecap
  uebung_9_3_vor.svg, elements.2.elements.2.attributes.stroke-linecap
  uebung_9_3_vor.svg, elements.2.elements.3.attributes.stroke-linecap
  uebung_9_3_vor.svg, elements.2.elements.4.attributes.stroke-linecap
  uebung_9_3_vor.svg, elements.2.elements.5.attributes.stroke-linecap
  uebung_9_4_vor.svg, elements.2.elements.1.attributes.stroke-linecap
  uebung_9_4_vor.svg, elements.2.elements.2.attributes.stroke-linecap
  uebung_9_5_vor.svg, elements.2.elements.0.attributes.stroke-linecap
  uebung_9_5_vor.svg, elements.2.elements.1.attributes.stroke-linecap
  uebung_9_5_vor.svg, elements.2.elements.2.attributes.stroke-linecap
  uebung_9_5_vor.svg, elements.2.elements.3.attributes.stroke-linecap
  uebung_9_5_vor.svg, elements.2.elements.4.attributes.stroke-linecap
  */
  stroke-linecap: round;
}

.svg-Faoixsl6q8{
  /*
  Used In:
  uebung_9_1_vor.svg, elements.2.elements.0.attributes.stroke-miterlimit
  uebung_9_1_vor.svg, elements.2.elements.1.attributes.stroke-miterlimit
  uebung_9_1_vor.svg, elements.2.elements.2.attributes.stroke-miterlimit
  uebung_9_2_vor.svg, elements.2.elements.0.attributes.stroke-miterlimit
  uebung_9_2_vor.svg, elements.2.elements.1.attributes.stroke-miterlimit
  uebung_9_2_vor.svg, elements.2.elements.2.attributes.stroke-miterlimit
  uebung_9_3_vor.svg, elements.2.elements.0.attributes.stroke-miterlimit
  uebung_9_3_vor.svg, elements.2.elements.1.attributes.stroke-miterlimit
  uebung_9_3_vor.svg, elements.2.elements.2.attributes.stroke-miterlimit
  uebung_9_3_vor.svg, elements.2.elements.3.attributes.stroke-miterlimit
  uebung_9_3_vor.svg, elements.2.elements.4.attributes.stroke-miterlimit
  uebung_9_3_vor.svg, elements.2.elements.5.attributes.stroke-miterlimit
  uebung_9_4_vor.svg, elements.2.elements.1.attributes.stroke-miterlimit
  uebung_9_4_vor.svg, elements.2.elements.2.attributes.stroke-miterlimit
  uebung_9_5_vor.svg, elements.2.elements.0.attributes.stroke-miterlimit
  uebung_9_5_vor.svg, elements.2.elements.1.attributes.stroke-miterlimit
  uebung_9_5_vor.svg, elements.2.elements.2.attributes.stroke-miterlimit
  uebung_9_5_vor.svg, elements.2.elements.3.attributes.stroke-miterlimit
  uebung_9_5_vor.svg, elements.2.elements.4.attributes.stroke-miterlimit
  */
  stroke-miterlimit: 10;
}

.svg-UF3eDJlAwN{
  /*
  Used In:
  uebung_9_1_vor.svg, elements.2.elements.0.attributes.stroke-linejoin
  uebung_9_1_vor.svg, elements.2.elements.1.attributes.stroke-linejoin
  uebung_9_1_vor.svg, elements.2.elements.2.attributes.stroke-linejoin
  uebung_9_2_vor.svg, elements.2.elements.0.attributes.stroke-linejoin
  uebung_9_2_vor.svg, elements.2.elements.1.attributes.stroke-linejoin
  uebung_9_2_vor.svg, elements.2.elements.2.attributes.stroke-linejoin
  uebung_9_3_vor.svg, elements.2.elements.0.attributes.stroke-linejoin
  uebung_9_3_vor.svg, elements.2.elements.1.attributes.stroke-linejoin
  uebung_9_3_vor.svg, elements.2.elements.2.attributes.stroke-linejoin
  uebung_9_3_vor.svg, elements.2.elements.3.attributes.stroke-linejoin
  uebung_9_3_vor.svg, elements.2.elements.4.attributes.stroke-linejoin
  uebung_9_3_vor.svg, elements.2.elements.5.attributes.stroke-linejoin
  uebung_9_4_vor.svg, elements.2.elements.1.attributes.stroke-linejoin
  uebung_9_4_vor.svg, elements.2.elements.2.attributes.stroke-linejoin
  uebung_9_5_vor.svg, elements.2.elements.0.attributes.stroke-linejoin
  uebung_9_5_vor.svg, elements.2.elements.1.attributes.stroke-linejoin
  uebung_9_5_vor.svg, elements.2.elements.2.attributes.stroke-linejoin
  uebung_9_5_vor.svg, elements.2.elements.3.attributes.stroke-linejoin
  uebung_9_5_vor.svg, elements.2.elements.4.attributes.stroke-linejoin
  */
  stroke-linejoin: round;
}
